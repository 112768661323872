<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_151_2635)">
            <g opacity="0.68" clip-path="url(#clip1_151_2635)">
                <path
                    d="M4.8 14.25L3.75 13.2L7.95 9L3.75 4.8L4.8 3.75L9 7.95L13.2 3.75L14.25 4.8L10.05 9L14.25 13.2L13.2 14.25L9 10.05L4.8 14.25Z"
                    fill="currentColor"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_151_2635">
                <rect width="18" height="18" fill="white" />
            </clipPath>
            <clipPath id="clip1_151_2635">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
